@media all {
  .page-break-after {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .page-break {
    page-break-before: always;
  }

  .page-content {
    page-break-after: always;
    width: 100%;
  }

  .page-content:last-child {
    page-break-after: auto;
  }

  .page-header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 1rem;
  }

  .repeating-section {
    page-break-after: always;
  }

  .last-row-on-page {
    border-bottom: 1px solid #000;
  }
}

@page {
  size: auto;
  margin: 5mm;
}

.print-container {
  font-family: "Times New Roman", Times, serif;
}
