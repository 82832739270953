@media print {
  .page-content {
    page-break-after: always;
    position: relative;
    height: 100vh;
    width: 100%;
  }

  .page-content:last-child {
    page-break-after: auto;
  }

  .page-header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 2rem;
  }

  .page-footer,
  .repeating-section {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
